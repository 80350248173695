import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import { createPinia } from 'pinia';

import { IonicVue } from '@ionic/vue';


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';
import './theme/customIcons.css';

import "vue-awesome-paginate/dist/style.css";

import BasePage from '@/components/base/BasePage.vue';
import { FontAwesomeIcon } from '@/plugins/font-awesome';
import VueApexCharts from 'vue3-apexcharts';
import Maska from 'maska';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';


import i18n from './i18n';

import Toast, { PluginOptions, POSITION } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

import VueGoogleMaps from '@fawmi/vue-google-maps';
import { VueReCaptcha } from 'vue-recaptcha-v3';

const options: PluginOptions = {
  position: POSITION.BOTTOM_CENTER,
};

const app = createApp(App)
    .use(IonicVue)
    .use(router)
    .use(createPinia())
    .use(i18n)
    .use(Maska)
    .use(Toast, options)
    .use(VueApexCharts)
    .use(VueGoogleMaps, {
        load: {
          key: process.env.VUE_APP_GOOGLE_API,
          libraries: 'places',

          // language: 'de',
        },
    });
dayjs.extend(localizedFormat);
dayjs.extend(duration);
app.provide('dayJS', dayjs);
app.component('base-page', BasePage);
app.component('fa-icon', FontAwesomeIcon);
const optRecaptcha: { siteKey: any } = { siteKey: "6LdObu0pAAAAAGb12Ugf_1xT1hABmU-DsW2OikcY" };
app.use(VueReCaptcha, optRecaptcha);

app.config.globalProperties.$dayjs = dayjs;

/* window['Pusher'] = require('pusher-js');
const isProduction = process.env.VUE_APP__WS_CONNECT_PRODUCTION === 'true';

window['Echo'] = new Echo({
  broadcaster: 'pusher',
  key: process.env.VUE_APP_WEBSOCKET_KEY,
  wsHost: process.env.VUE_APP_WEBSOCKET_SERVER,
  authEndpoint: process.env.VUE_APP_BACKEND_URL + '/broadcasting/auth',
  wsPort: isProduction ? 6002 : 6001,
  wssPort: isProduction ? 6002 : 6001,
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss'],
  //disabledTransports: ['sockjs', 'xhr_polling', 'xhr_streaming']
}); */

router.isReady().then(() => {
  app.mount('#app');
});
