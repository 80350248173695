import {defineStore} from "pinia";

import _ from "lodash";

import dayjs from "dayjs";
import {isNew as _isNew} from "@/helpers/dive";
import {RepositoryFactory} from "@/repositories/RepositoryFactory";
import useBackendFetch from "@/repositories/BackendFetch";

const DivesRepository = RepositoryFactory.get("dives");

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useDiveStore = defineStore("dive", {
  state: () => {
    const dive = {
      id: null,
      profile: null,
      ceil: null,
      temp: null,
      sampleRate: null,
      rebData: null,
      compartmentData: null,
      sac: null,
      details: {},
      advLab: {},
      dopplers: [],
    };
    const tanks = [];
    const gfs = { hi: 80, low: 80 };
    return {
      dive,
      gfs,
      tanks,
    };
  },
  actions: {
    diveCalculations(data) {
      const diveDate = dayjs(data.date);
      let prevMarker = null;
      let sampleRate = null;
      const sacs = [];
      const tanks = [];
      data.profile.forEach((element, index) => {
        if (element?.gases?.sac) {
          sacs.push(element?.gases?.sac);
        }
        const d = diveDate.clone().add(element.timesec, "seconds");
        if (!sampleRate) sampleRate = element.timesec;

        data.profile[index].date = d.valueOf();
        data.profile[index].temp = data.profile[index].temp * 1;
        let markerUrl = null;
        if (element.marker && element.marker != prevMarker) {
          markerUrl = "/assets" + element.marker.replace("/storage", "");
          prevMarker = element.marker;
        }
        data.profile[index].pictureSettings = { src: markerUrl };
        if (data.profile[index].vs >= 8)
          data.profile[index].strokeSettings = { stroke: "#ff0000" };
        else if (data.profile[index].vs >= 6)
          data.profile[index].strokeSettings = { stroke: "#ffFF00" };
        else data.profile[index].strokeSettings = { stroke: "#000000" };
        if (
          data.profile[index].calculated !== undefined &&
          data.profile[index].calculated === true
        ) {
          data.profile[index].strokeSettings.strokeWidth = 10;
          data.profile[index].strokeSettings.strokeDasharray = [10, 5];
        }
        data.profile[index].depthChart = data.profile[index].depth * -1;
        if (index == 0) {
          data.profile[index].ceil = 0;
          data.profile[index].decotime = 999;
          data.profile[index].ndl = 99;
        } else {
          data.profile[index].ceil = data.ceil[index - 1].ceil;
          data.profile[index].decotime = data.ceil[index - 1].decoTime;
          data.profile[index].ndl = data.ceil[index - 1].ndl;
          data.profile[index].totalCeil = data.ceil[index - 1].ceilsTime;
        }
        if (element.gases && (tanks.length === 0 || tanks.length > 0))
          tanks.push(JSON.parse(JSON.stringify(element)));

        if (data.profile[index].gfMax === data.gf.value) {
          data.profile[index].gfMaxBulletSettings = {fill: "#ff0000"};
        }
      });
      data.dopplers = this.dive.dopplers;
      this.dive = data;
      this.dive.sampleRate = sampleRate;
      this.tanks = tanks;
      this.dive.sac = _.round(sacs.reduce((a, b) => a + b, 0) / sacs.length);
      this.dive.isNew = true;
      return true;
    },
    async get(diveId) {
      try {
        const { data: dopplers, error } = await useBackendFetch(
          `doppler/${diveId}`
        )
          .get()
          .json();
        this.dive.dopplers = dopplers.value;
        const { data } = await DivesRepository.get(diveId, this.gfs);
        this.diveCalculations(data);

        return true;
      } catch (error) {
        return false;
      }
    },

    async deleteDive(diveId) {
      if (diveId == null) {
        diveId = this.dive.id;
      }
      //this.dive = { id: null, profile: null, ceil: null,temp:null, rebData: null };
      return await DivesRepository.deleteDive(diveId);
    },
    async updateGfs(hi, low) {
      this.gfs.hi = hi;
      this.gfs.low = low;
      const r = await this.get(this.dive.id);
      return r;
    },
    async toggleDCS() {
      const { data, error } = await useBackendFetch(
        `dives/toggle-dcs/${this.dive.id}`
      )
        .put({ data: null })
        .json();
      await this.get(this.dive.id);
    },

    async saveTank(data) {
      const r = await DivesRepository.saveTank(data, this.dive.id);
      this.diveCalculations(r.data.dive);
      return r;
    },

    async savePPO2(data) {
      const r = await DivesRepository.savePPO2(data, this.dive.id);
      this.diveCalculations(r.data.dive);
      return r;
    },

    async deleteTank(data) {
      const r = await DivesRepository.deleteTank(data, this.dive.id);
      this.diveCalculations(r.data.dive);
      return r;
    },
    async deletePPO2(data) {
      const r = await DivesRepository.deletePPO2(data, this.dive.id);
      this.diveCalculations(r.data.dive);
      return r;
    },
    async saveDiluent(data) {
      const r = await DivesRepository.saveDiluent(data, this.dive.id);
      this.diveCalculations(r.data.dive);
      return r;
    },
    async setDiveLocation(position) {
      const location = { position: position };
      this.dive.details = _.merge(this.dive.details, location);
    },
    async updateDetail(data) {
      if (data?.problems) {
        this.dive.details.problems = {};
      }
      this.dive.details = _.mergeWith(this.dive.details, data, (srcValue, value) => {
        if (_.isArray(srcValue)) {
          return value;
        }
      });
      const r = await DivesRepository.saveDetail(
        this.dive.details,
        this.dive.id
      );
      await this.get(this.dive.id);
    },
    async updateAdvLab(formData) {
      if (!this.dive.advLab) {
        this.dive.advLab = {};
      }
      let [firstKey] = Object.keys(formData);
      if (firstKey && firstKey in this.dive.advLab) {
        this.dive.advLab[firstKey] = null;
      }
      this.dive.advLab = _.merge(this.dive.advLab, formData);
      console.log(this.dive.advLab);
      const { data, error } = await useBackendFetch(
        `dives/adv-lab/${this.dive.id}`
      ).post({ data: this.dive.advLab })
          .json();

      await this.get(this.dive.id);
    },
    async updateDoppler(formData) {
      const { data, error } = await useBackendFetch(
        `doppler/update/${this.dive.id}`
      )
        .post(formData)
        .json();
      const { data: dopplers, error: getError } = await useBackendFetch(
        `doppler/${this.dive.id}`
      )
        .get()
        .json();
      this.dive.dopplers = dopplers.value;
    },
    async deleteDoppler(id) {
      const { error } = await useBackendFetch("/doppler/" + id)
        .delete()
        .json();
      if (!error.value) {
        await this.get(this.dive.id);
      }
    },
  },

  getters: {
    // automatically infers the return type as a number

    rebData(state) {
      const reb = { diluent: null, ppo2s: [] };
      if (state.dive.rebData) {
        const reb = { diluent: state.dive.rebData.diluent, ppo2s: [] };
        if (Array.isArray(state.dive.rebData.ppo2)) {
          reb.ppo2s = state.dive.rebData.ppo2;
        } else {
          reb.ppo2s.push({ time: 0, ppo2: state.dive.rebData.ppo2 });
        }
      }
      return reb;
    },
    getPosition(state) {
      if (state.details && state.details.location)
        return state.details.location;
      return null;
    },
    isNew(state) {
      _isNew(state.dive);
    },
    getDiveSite(state) {
      if (state.details && state.details.location)
        return state.details.location.divesite;
      return null;
    },

    getInfos(state) {
      if (state.details && state.details.infos) return state.details.infos;
      return null;
    },
  },
});
