import { createI18n } from "vue-i18n";

import { languages } from "@/languages";

const messages = Object.assign(languages);
const userSettings =
  localStorage.getItem("user_settings") &&
  localStorage.getItem("user_settings") != "undefined"
    ? JSON.parse(localStorage.getItem("user_settings"))
    : null;

const getDeviceLang = () => {
  let lang = window.navigator.languages ? window.navigator.languages[0] : null;
  lang =
    lang ||
    window.navigator.language ||
    window.navigator.browserLanguage ||
    window.navigator.userLanguage;

  let shortLang = lang;
  if (shortLang.indexOf("-") !== -1) shortLang = shortLang.split("-")[0];

  if (shortLang.indexOf("_") !== -1) shortLang = shortLang.split("_")[0];
  return { lang, shortLang };
};

const deviceLang = getDeviceLang();

const defaultLocale = userSettings?.language
  ? userSettings.language
  : deviceLang.shortLang;

const instance = createI18n({
  legacy: false,
  globalInjection: true,
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
  datetimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      },
      time: {
        hour: "2-digit",
        minute: "2-digit",
      },
      monthShortName: {
        month: "short",
      },
    },
    it: {
      short: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      },
      time: {
        hour: "2-digit",
        minute: "2-digit",
      },
      monthShortName: {
        month: "short",
      },
    },
  },
});

export default instance;
export const i18n = instance.global;
